@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@layer utilities {
  /*#region bg-growing-underline*/
  .bg-growing-underline-from-c {
    background-size: 0% 0.1em;
    background-position: 50% 100%;
    @apply bg-no-repeat transition-all duration-200;
  }
  .bg-growing-underline-from-c:hover {
    background-size: 100% 0.1em;
  }
  .bg-growing-underline-from-c:focus {
    background-size: 100% 0.1em;
  }
  /*#endregion*/

  /*#region box-with-arrow*/
  
  /*#endregion*/

  .border-dashed-wide {
      background-repeat: no-repeat;
      background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100% ;

      background-image:
      repeating-linear-gradient(0deg, rgba(53 170 139), rgba(53 170 139) 20px, transparent 20px, transparent 25px ), 
      repeating-linear-gradient(90deg, rgba(53 170 139), rgba(53 170 139) 20px, transparent 20px, transparent 25px), 
      repeating-linear-gradient(180deg, rgba(53 170 139), rgba(53 170 139) 20px, transparent 20px, transparent 25px), 
      repeating-linear-gradient(270deg, rgba(53 170 139), rgba(53 170 139) 20px, transparent 20px, transparent 25px ) 
  ; 
  }
  .TODO {
    @apply  border-2 border-red-500 border-dashed 
  }
}
html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}